@import '~styles/global';

.Container {
  position: relative;
  background: #fff;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;

  @include medium {
    border: 1px solid #e6e6e6;
    border-radius: 12px;
  }

  .Header {
    display: flex;
    align-items: center;
    padding: 16px 16px 8px;

    @include medium {
      padding: 24px 24px 16px;
    }
  }

  .Title {
    font-size: 20px;
    font-weight: $font-weight-bold;
    margin-left: 8px;
  }

  .Content {
    @include medium {
      padding: 0 24px;
    }
  }
}
