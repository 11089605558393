@import '~styles/global';

.ArticleBlock {
  display: block;

  &.Card {
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    box-shadow: 0 2px 3px #e6e6e6;
    display: flex;
    flex-direction: column;
  }

  &.Feed {
    display: flex;
    margin: 0 16px;
    padding: 12px 0;
    border-top: 1px solid #e6e6e6;

    &.First {
      border-top: 0;
    }
  }

  &.Overlay {
    position: relative;
  }

  &.TextOnly {
    border-top: 1px solid #e6e6e6;
    padding-top: 16px;
    align-self: stretch;
  }

  &.ArticleAd {
    display: flex;
    justify-content: center;
    align-items: center;

    @include medium {
      background-color: $sbl-gray-11;
    }
  }
}

.ImageContent {
  display: block;

  .Card & {
    border-bottom: 6px solid #e6e6e6;
  }

  .Feed & {
    flex: 0 0 120px;
    margin-right: 10px;
  }
}

.ImageContainer {
  display: block;
  position: relative;
  overflow: hidden;

  .Card & {
    padding-bottom: 56.25%;
  }

  .Feed & {
    border-radius: 8px;
    padding-bottom: 80%;
  }

  .HeroLarge & {
    border-radius: 12px;
    padding-bottom: 75%;
  }

  .HeroSmall & {
    border-radius: 12px;
    padding-bottom: 50%;
  }

  .Overlay & {
    padding-bottom: 75%;
  }
}

.Image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.TextContent {
  display: block;

  .Card & {
    padding: 12px 16px 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .Feed & {
    display: flex;
    flex-direction: column;
  }

  .Overlay & {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 16px 16px;
  }

  .HeroLarge & {
    margin-top: 10px;
  }

  .HeroSmall & {
    margin-top: 8px;
  }

  .TextOnly & {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.GenderSport {
  display: block;
  font-size: 14px;
  color: #00bbf2;
  text-transform: uppercase;
  font-weight: $font-weight-regular;

  .Overlay & {
    color: #fff;
  }
}

.Title {
  display: block;
  font-size: 18px;
  color: #48494a;
  font-weight: $font-weight-heavy;

  .Card & {
    font-size: 20px;
    line-height: 22px;
    margin-top: 12px;
    flex: 1;
  }

  .Feed & {
    font-size: 18px;
    line-height: 20px;
    margin-top: 6px;
    flex: 1;
  }

  .HeroLarge & {
    font-size: 32px;
    line-height: 30px;
    margin-top: 8px;
  }

  .HeroSmall & {
    font-size: 24px;
    line-height: 24px;
    margin-top: 8px;
  }

  .Overlay & {
    color: #fff;
    font-size: 22px;
    line-height: 24px;
    margin-top: 8px;
  }

  .TextOnly & {
    font-size: 18px;
    line-height: 22px;
    margin-top: 6px;
    flex: 1;
  }
}

.ArticleBlock:not(.Overlay) {
  @include hover {
    .Title {
      color: #0d6fdf;
    }
  }
}

.Description {
  display: block;
  color: #48494a;
  font-weight: $font-weight-light;

  .Card & {
    font-size: 14px;
    margin-top: 16px;
    line-height: 17px;
    flex: 1;
  }

  .HeroLarge & {
    font-size: 16px;
    line-height: 19px;
    margin-top: 8px;
  }

  .HeroSmall & {
    font-size: 14px;
    line-height: 17px;
    margin-top: 8px;
  }
}

.AuthorName {
  display: block;
  font-size: 14px;
  color: #a5a6a7;
  font-weight: $font-weight-regular;

  .Card & {
    margin-top: 24px;
  }

  .Feed & {
    margin-top: 8px;
  }

  .HeroLarge & {
    margin-top: 24px;
  }

  .HeroSmall & {
    margin-top: 16px;
  }

  .Overlay & {
    color: #fff;
    font-size: 12px;
    margin-top: 16px;
  }

  .TextOnly & {
    margin-top: 8px;
  }
}
