@import '~styles/global';

.SponsorSpot {
  margin-top: 30px;
}

.Bottom {
  margin: 40px 0;

  @include large {
    margin: 24px 0;
  }
}

.BottomColumns {
  @include large {
    display: flex;
    flex-direction: row-reverse;
  }
}

.Feed {
  padding-bottom: 24px;
  margin-top: 40px;

  @include large {
    margin-top: 0;
  }
}

.Video {
  @include medium {
    margin-bottom: 30px;
  }
}

.RightColumn {
  display: none;

  @include large {
    display: block;
    flex: 0 0 300px;
    margin-top: 0;
    margin-left: 24px;
  }
}

.RightColumn :global {
  .sponsor {
    margin-bottom: 15px;
  }
}
