@import '~styles/global';

.Block {
  margin-top: 24px;
}

.Container {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  column-gap: 24px;
  row-gap: 8px;
  padding: 24px 0;
}

.Main {
  grid-row: (1 / span) 3;
}

.Secondary {
  grid-column: (2 / span) 2;
}
