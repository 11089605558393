@import '~styles/global';

$img-path: '../../../assets/images';

.sponsor {
  display: block;
  background-size: contain;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}

.sponsor--banner {
  max-width: 320px;
  height: 50px;

  @include medium {
    max-width: 728px;
    height: 90px;
  }
}

ins.sponsor--banner {
  max-width: 320px;
  height: 50px !important;

  @include medium {
    max-width: 728px;
    height: 90px !important;
  }
}

.sponsor--square {
  max-width: 300px;
  height: 250px;
}

.sponsor--wafd-bank {
  &.sponsor--square {
    background-image: url('#{$img-path}/sponsors/wafd-bank/square.jpg');

    @include medium {
      background-image: url('#{$img-path}/sponsors/wafd-bank/square.jpg');
    }
  }

  &.sponsor--banner {
    background-image: url('#{$img-path}/sponsors/wafd-bank/banner-small.jpg');

    @include medium {
      background-image: url('#{$img-path}/sponsors/wafd-bank/banner-large.jpg');
    }
  }
}

.sponsor--fan-app-ios {
  border: 1px #e6e6e6 solid;

  &.sponsor--square {
    background-image: url('#{$img-path}/sponsors/fan-app-ios/banner-square.png');
  }

  &.sponsor--banner {
    background-image: url('#{$img-path}/sponsors/fan-app-ios/banner-long-small.png');

    @include small {
      max-width: 404px;
    }

    @include medium {
      background-image: url('#{$img-path}/sponsors/fan-app-ios/banner-large.png');
    }
  }

  &.sponsor--flag {
    background-image: url('#{$img-path}/sponsors/fan-app-ios/banner-flag.png');
  }
}

.sponsor--cots {
  border: 1px #e6e6e6 solid;

  &.sponsor--banner {
    background-image: url('#{$img-path}/sponsors/cots/banner.jpg');

    @include small {
      max-width: 404px;
    }
  }
}

.sponsor--academy {
  border: 1px #e6e6e6 solid;

  &.sponsor--square {
    background-image: url('#{$img-path}/sponsors/academy/square.jpg');
  }

  &.sponsor--banner {
    background-image: url('#{$img-path}/sponsors/academy/banner.jpg');

    @include small {
      max-width: 404px;
    }

    @include medium {
      background-image: url('#{$img-path}/sponsors/academy/banner.jpg');
    }
  }

  &.sponsor--flag {
    background-image: url('#{$img-path}/sponsors/academy/banner.jpg');
  }
}

.sponsor--fan-app-android {
  border: 1px #e6e6e6 solid;

  &.sponsor--square {
    background-image: url('#{$img-path}/sponsors/fan-app-android/banner-square.png');
    max-width: 450px;
  }

  &.sponsor--banner {
    background-image: url('#{$img-path}/sponsors/fan-app-android/banner-long-small.png');

    @include small {
      max-width: 404px;
    }

    @include medium {
      background-image: url('#{$img-path}/sponsors/fan-app-android/banner-large.png');
      max-width: 1092px;
    }
  }

  &.sponsor--flag {
    background-image: url('#{$img-path}/sponsors/fan-app-android/banner-flag.png');
  }
}

.sponsor--nike {
  &.sponsor--square {
    background-image: url('#{$img-path}/sponsors/nike/square.jpg');

    @include medium {
      background-image: url('#{$img-path}/sponsors/nike/square.jpg');
      height: 600px;
    }
  }

  &.sponsor--banner {
    background-image: url('#{$img-path}/sponsors/nike/banner-small.jpg');

    @include medium {
      background-image: url('#{$img-path}/sponsors/nike/banner-large.jpg');
    }
  }
}

.sponsor--jersey_mike {
  &.sponsor--square {
    background-image: url('#{$img-path}/sponsors/jersey-mikes/square2.png');

    @include medium {
      background-image: url('#{$img-path}/sponsors/jersey-mikes/square2.png');
    }
  }

  &.sponsor--banner {
    background-image: url('#{$img-path}/sponsors/jersey-mikes/banner.png');

    @include medium {
      background-image: url('#{$img-path}/sponsors/jersey-mikes/banner.png');
    }
  }
}

.sponsor--fan-app {
  &.sponsor--square {
    background-image: url('#{$img-path}/sponsors/fan-app/fan-app.jpeg');
  }
}

.sponsor--adot {
  &.sponsor--square {
    background-image: url('#{$img-path}/sponsors/adot/square.jpg');

    @include medium {
      height: 600px;
    }
  }

  &.sponsor--banner {
    background-image: url('#{$img-path}/sponsors/adot/banner.jpg');
  }
}

.sponsor--nfhs-live {
  margin-top: 24px;
  background-image: url('#{$img-path}/sponsors/nfhs/NFHS-small.png');

  @include medium {
    margin-top: 36px;
    background-image: url('#{$img-path}/sponsors/nfhs/NFHS-large.png');
  }
}

.sponsor--rank-one {
  &.sponsor--banner {
    background-image: url('#{$img-path}/sponsors/rank-one/banner-long-one.jpg');

    @include small {
      max-width: 404px;
    }

    @include medium {
      max-width: 1092px;
    }
  }
}

.sponsor--rank-one-two {
  &.sponsor--banner {
    background-image: url('#{$img-path}/sponsors/rank-one/banner-long-two.jpg');

    @include small {
      max-width: 404px;
    }

    @include medium {
      max-width: 1092px;
    }
  }
}

.sponsor--wiaa {
  &.sponsor--flag {
    background-color: $sbl-blue-2;
    background-image: url('#{$img-path}/sponsors/wiaa/banner-flag.png');
  }
}
