@import '~styles/global';

.Feed {
  padding-bottom: 24px;
}

.Cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.FullWidth {
  grid-column: 1 / -1;
}
